@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&display=swap);
body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

html, body {
   padding:0px;
   margin:0px;
   width: 100vw;
   /* overflow-x: hidden;
 
   */

}

* img {
   -webkit-user-select: none; /* Safari */ /* Firefox */
-ms-user-select: none; /* IE10+/Edge */
user-select: none; /* Standard */
}

body {

   /* min-height: 120vh; */

   /* height: 100vh; /* Fallback for browsers that do not support Custom Properties */
   /* height: calc(var(--vh, 1vh) * 100); */ 
   background-color: white;
   width:100vw;

   overflow-x:hidden;
   /* overflow:hidden; */
}

.App {

   text-align: center;
   display: -webkit-flex;
   display: flex;
   /* font-family: 'Montserrat', sans-serif; */

   font-family: "Roboto Slab", serif;
   width: 100vw;
   padding-right: 15px; /* Avoid width reflow */
   /* height: 100vh; */
   width:100vw;

  
}

.main{
   display: -webkit-flex;
   display: flex;
   width:100vw;
   transition: -webkit-filter 1.5ohs;
   transition: filter 1.5ohs;
   transition: filter 1.5ohs, -webkit-filter 1.5ohs;
   transition: -webkit-filter 0.34s;
}

.main.blur {
    filter:blur(10px);
   -webkit-filter: blur(10px);
}

.main.unblur{
   -webkit-backdrop-filter: blur(0px);
   backdrop-filter: blur(0px);
   transition: backdrop-filter 0.2s;
   transition: backdrop-filter 0.2s, -webkit-backdrop-filter 0.2s;
   transition: -webkit-backdrop-filter 0.2s;
}

.main.blur {

}

.testImage {
   position:absolute;
}

.testImage img {
   width:90vw;
   height:50vh;
   /* border:2px solid red; */
}

.leftColumn {
   width: 24%;
   min-width: 200px;
   transition: all 0.32s;
}

.leftColumn__inner {
   width: 18%;

   padding-left: 3.4%;
   padding-top: 3.4%;
   position: fixed;
}

.leftColumn ul {
   text-decoration: none;
   list-style-type: none;
   margin: 0px;
   padding: 0px;
   margin-top: 40px;
   font-size: 18px;
   font-weight: 400;
}

.leftColumn ul li {
   margin-bottom: 20px;
}

.leftColumn ul li.selected a {
   color: #57acc5;
   text-decoration: underline;
}

nav a {
   text-decoration: none;
   color: #555555;
   cursor: pointer;
}

.leftColumn h1 {
   font-size: 36px;
   color: #41697f;
   width: 14%;
   margin: 0px;
   padding: 0px;
   font-weight: bold;
   cursor: pointer;
}

nav a:hover {
   /* border-bottom: 1px solid #454545; */
   text-decoration: underline;
}

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

.App-header {
  
   min-height: 100vh;
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: column;
           flex-direction: column;
   -webkit-align-items: center;
           align-items: center;
   -webkit-justify-content: center;
           justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

@-webkit-keyframes App-logo-spin {
   from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
   }
   to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
   }
}

@keyframes App-logo-spin {
   from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
   }
   to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
   }
}

.gallery {
   /* filter:blur(10px);
   -webkit-filter: blur(10px); */
   padding-top: 3.3vw;
   text-align: left;
   line-height: 0px;
   width: 80%;
   padding-bottom: 3.3vw;
}

.gallery .GalleryImage {
   margin: 3%;
   background-color: #2dbce7;

   display: -webkit-inline-flex;

   display: inline-flex;
   -webkit-align-items: center;
           align-items: center;

   -webkit-justify-content: center;

           justify-content: center;
   cursor: pointer;
   transition: all 0.32s;
}
.gallery .GalleryImage .image {
   opacity: 1;
   line-height: 0px;
   height: 160px;

   transition: all 0.34s;
}

.gallery .GalleryImage:hover {
   /* transform:scale(1.024); */
}

.gallery .GalleryImage:hover .image {
   opacity: 0.5;
}

.gallery .GalleryImage:hover .mag {
   opacity: 1;
   -webkit-transform: scale(1.4);
           transform: scale(1.4);
}

.GalleryImage .mag {
   position: absolute;
   width: 20px;
   height: 20px;
   z-index: 10;
   opacity: 0;

   -webkit-transform-origin: center;

           transform-origin: center;
   transition: all 0.34s;
}



.BlurBackground {
   position: fixed;
   width: 200vw;
   height: 200vh;
   pointer-events: none;
   
  
}

.BlurBackground.blur {
  
   transition: background-color 1s;
   background-color: rgba(211, 229, 237, 0.7);
   pointer-events: all;
  
}


.BlurBackground.unblur{
   transition: background-color 1s;
   background-color: rgba(211, 229, 237, 0);
}


.LargeImage__container {
   margin: auto;
   background-color: white;
  
   display: inline-block;
   position: absolute;

   border:1px solid red;

  
}

.LargeImage img {
   position:absolute;
   top:30px;
   left:30px;
  
   /* width: 80vw;
   height: 80vh; */
   /* object-fit: contain; */
   
   
   /* filter:blur(1px); */
   /* -webkit-filter: blur(1px); */
}


.cross {
   display:none;
   position: fixed;
   top: 20px;
   right: 20px;
   width: 26px;
   opacity: 0.8;

   cursor: pointer;
   z-index: 200000;
}

.cross.show{
   display:block;
}



.About {
   text-align: left;
   padding-top: 2vw;
   width: 80%;
   max-width: 1000px;
   padding-bottom: 100px;
}

.About h1 {
   margin-top: 40px;
   text-align: center;
   width: 100%;
   font-size: 48px;
   margin-bottom: 70px;
   color: #656565;
   color: #41697f;
}

.About .banner img {
   width: 100%;
}

.About .banner {
   width: 100%;
}

.About .row img {
   width: 100%;
}

.About .row {
   display: -webkit-flex;
   display: flex;
   font-size: 16px;
   line-height: 27px;
   color: #555555;
   margin-top: 80px;
   letter-spacing: 0.3px;
}

.About .left {
   width: 50%;
   padding-right: 18px;
}

.About .right {
   width: 50%;
   padding-left: 18px;
}

.About h2 {
   margin-top: 0px;
   font-size: 30px;
}

header {
   display: none;

   position: fixed;
   top: 0px;
   z-index: 100;

   background-color: rgba(211, 229, 237, 0.6);

   -webkit-backdrop-filter: blur(14px);
   backdrop-filter: blur(14px);

   transition: all 0.5s;
   height: 100px;
   /* height: 200px; */
}

header h1 {
   font-size: 20px;

   color: #41697f;

   margin: 10px 20px;
   padding: 0px;
   font-weight: bold;
   cursor: pointer;
}


@media only screen and (max-width: 700px) {
   body {
      /* overflow: hidden; */
   }

   .leftColumn {
      margin-left: -200px;
   }

   .gallery .GalleryImage .image {
      height: 200px;
   }

   .gallery {
      width: 100%;
   }

   header {
      display: block;
      width: 100vw;
   }

   /* .leftColumn__inner {
    background-color: red;
    left:-200px;
  } */
}

#nav-icon1,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
   width: 60px;
   height: 45px;
   position: relative;
   margin: 50px auto;
   -webkit-transform: rotate(0deg);
   transform: rotate(0deg);
   transition: 0.5s ease-in-out;
   cursor: pointer;
}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
   display: block;
   position: absolute;
   height: 9px;
   width: 100%;
   background: #d3531a;
   border-radius: 9px;
   opacity: 1;
   left: 0;
   -webkit-transform: rotate(0deg);
   transform: rotate(0deg);
   transition: 0.25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
   top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
   top: 18px;
}

#nav-icon3 span:nth-child(4) {
   top: 36px;
}

#nav-icon3.open span:nth-child(1) {
   top: 18px;
   width: 0%;
   left: 50%;
}

#nav-icon3.open span:nth-child(2) {
   -webkit-transform: rotate(45deg);
   transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
   -webkit-transform: rotate(-45deg);
   transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
   top: 18px;
   width: 0%;
   left: 50%;
}

/* Icon 4 */

.FullImage {
   
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
  
    /* top:0px;
    left:0px;
    width:100%;
    height:100vh;
    box-sizing:border-box;
    overflow-x: hidden; */
   
 }

 .FullImage.show {
    opacity: 1;
    pointer-events: all;
 }


.FullImage .thumbnail{
    filter:blur(5px);
    -webkit-filter: blur(5px);
    position:fixed;
    /* top:50%;
    left:50%;
    transform: translate(-50%, -50%); */
   
 }
 
 .FullImage .image{
    opacity: 0;
    transition: opacity 2s;
    position:fixed;
    /* top:50%;
   
    transform: translate(0, -50%); */
 }
 
 .FullImage .image.show{
    opacity: 1;
    transition: opacity 1s;
    /* ; */
 }


 .LargeImage .note {
    font-size: 16px;
    position: absolute;
    text-align: center;
    /* color:#57ACC5; */
    color: #959595;
    bottom: 27px;
    /* right:calc(4vw + 2px); */
    left: 50%;
 
    -webkit-transform: translate(-50%, 0%);
 
            transform: translate(-50%, 0%);
    /* margin-right:auto;
   margin-left:auto; */
    line-height: 14px;
    font-weight: bold;
 }
 .LargeImage .note span {
    font-size: 11px;
    font-weight: 400;
 }
 
 .FullImage .frame {
    box-sizing:border-box;
    position:fixed;
    
   
 }
