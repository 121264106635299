@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&display=swap");




html, body {
   padding:0px;
   margin:0px;
   width: 100vw;
   /* overflow-x: hidden;
 
   */

}

* img {
   -webkit-user-select: none; /* Safari */        
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* IE10+/Edge */
user-select: none; /* Standard */
}

body {

   /* min-height: 120vh; */

   /* height: 100vh; /* Fallback for browsers that do not support Custom Properties */
   /* height: calc(var(--vh, 1vh) * 100); */ 
   background-color: white;
   width:100vw;

   overflow-x:hidden;
   /* overflow:hidden; */
}

.App {

   text-align: center;
   display: flex;
   /* font-family: 'Montserrat', sans-serif; */

   font-family: "Roboto Slab", serif;
   width: 100vw;
   padding-right: 15px; /* Avoid width reflow */
   /* height: 100vh; */
   width:100vw;

  
}

.main{
   display: flex;
   width:100vw;
   transition: filter 1.5ohs;
   transition: -webkit-filter 0.34s;
}

.main.blur {
    filter:blur(10px);
   -webkit-filter: blur(10px);
}

.main.unblur{
   -webkit-backdrop-filter: blur(0px);
   backdrop-filter: blur(0px);
   transition: backdrop-filter 0.2s;
   transition: -webkit-backdrop-filter 0.2s;
}

.main.blur {

}

.testImage {
   position:absolute;
}

.testImage img {
   width:90vw;
   height:50vh;
   /* border:2px solid red; */
}

.leftColumn {
   width: 24%;
   min-width: 200px;
   transition: all 0.32s;
}

.leftColumn__inner {
   width: 18%;

   padding-left: 3.4%;
   padding-top: 3.4%;
   position: fixed;
}

.leftColumn ul {
   text-decoration: none;
   list-style-type: none;
   margin: 0px;
   padding: 0px;
   margin-top: 40px;
   font-size: 18px;
   font-weight: 400;
}

.leftColumn ul li {
   margin-bottom: 20px;
}

.leftColumn ul li.selected a {
   color: #57acc5;
   text-decoration: underline;
}

nav a {
   text-decoration: none;
   color: #555555;
   cursor: pointer;
}

.leftColumn h1 {
   font-size: 36px;
   color: #41697f;
   width: 14%;
   margin: 0px;
   padding: 0px;
   font-weight: bold;
   cursor: pointer;
}

nav a:hover {
   /* border-bottom: 1px solid #454545; */
   text-decoration: underline;
}

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

.App-header {
  
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

@keyframes App-logo-spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

.gallery {
   /* filter:blur(10px);
   -webkit-filter: blur(10px); */
   padding-top: 3.3vw;
   text-align: left;
   line-height: 0px;
   width: 80%;
   padding-bottom: 3.3vw;
}

.gallery .GalleryImage {
   margin: 3%;
   background-color: #2dbce7;

   display: inline-flex;
   align-items: center;

   justify-content: center;
   cursor: pointer;
   transition: all 0.32s;
}
.gallery .GalleryImage .image {
   opacity: 1;
   line-height: 0px;
   height: 160px;

   transition: all 0.34s;
}

.gallery .GalleryImage:hover {
   /* transform:scale(1.024); */
}

.gallery .GalleryImage:hover .image {
   opacity: 0.5;
}

.gallery .GalleryImage:hover .mag {
   opacity: 1;
   transform: scale(1.4);
}

.GalleryImage .mag {
   position: absolute;
   width: 20px;
   height: 20px;
   z-index: 10;
   opacity: 0;

   transform-origin: center;
   transition: all 0.34s;
}



.BlurBackground {
   position: fixed;
   width: 200vw;
   height: 200vh;
   pointer-events: none;
   
  
}

.BlurBackground.blur {
  
   transition: background-color 1s;
   background-color: rgba(211, 229, 237, 0.7);
   pointer-events: all;
  
}


.BlurBackground.unblur{
   transition: background-color 1s;
   background-color: rgba(211, 229, 237, 0);
}


.LargeImage__container {
   margin: auto;
   background-color: white;
  
   display: inline-block;
   position: absolute;

   border:1px solid red;

  
}

.LargeImage img {
   position:absolute;
   top:30px;
   left:30px;
  
   /* width: 80vw;
   height: 80vh; */
   /* object-fit: contain; */
   
   
   /* filter:blur(1px); */
   /* -webkit-filter: blur(1px); */
}


.cross {
   display:none;
   position: fixed;
   top: 20px;
   right: 20px;
   width: 26px;
   opacity: 0.8;

   cursor: pointer;
   z-index: 200000;
}

.cross.show{
   display:block;
}



.About {
   text-align: left;
   padding-top: 2vw;
   width: 80%;
   max-width: 1000px;
   padding-bottom: 100px;
}

.About h1 {
   margin-top: 40px;
   text-align: center;
   width: 100%;
   font-size: 48px;
   margin-bottom: 70px;
   color: #656565;
   color: #41697f;
}

.About .banner img {
   width: 100%;
}

.About .banner {
   width: 100%;
}

.About .row img {
   width: 100%;
}

.About .row {
   display: flex;
   font-size: 16px;
   line-height: 27px;
   color: #555555;
   margin-top: 80px;
   letter-spacing: 0.3px;
}

.About .left {
   width: 50%;
   padding-right: 18px;
}

.About .right {
   width: 50%;
   padding-left: 18px;
}

.About h2 {
   margin-top: 0px;
   font-size: 30px;
}

header {
   display: none;

   position: fixed;
   top: 0px;
   z-index: 100;

   background-color: rgba(211, 229, 237, 0.6);

   -webkit-backdrop-filter: blur(14px);
   backdrop-filter: blur(14px);

   transition: all 0.5s;
   height: 100px;
   /* height: 200px; */
}

header h1 {
   font-size: 20px;

   color: #41697f;

   margin: 10px 20px;
   padding: 0px;
   font-weight: bold;
   cursor: pointer;
}


@media only screen and (max-width: 700px) {
   body {
      /* overflow: hidden; */
   }

   .leftColumn {
      margin-left: -200px;
   }

   .gallery .GalleryImage .image {
      height: 200px;
   }

   .gallery {
      width: 100%;
   }

   header {
      display: block;
      width: 100vw;
   }

   /* .leftColumn__inner {
    background-color: red;
    left:-200px;
  } */
}

#nav-icon1,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
   width: 60px;
   height: 45px;
   position: relative;
   margin: 50px auto;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   -webkit-transition: 0.5s ease-in-out;
   -moz-transition: 0.5s ease-in-out;
   -o-transition: 0.5s ease-in-out;
   transition: 0.5s ease-in-out;
   cursor: pointer;
}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
   display: block;
   position: absolute;
   height: 9px;
   width: 100%;
   background: #d3531a;
   border-radius: 9px;
   opacity: 1;
   left: 0;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   -webkit-transition: 0.25s ease-in-out;
   -moz-transition: 0.25s ease-in-out;
   -o-transition: 0.25s ease-in-out;
   transition: 0.25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
   top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
   top: 18px;
}

#nav-icon3 span:nth-child(4) {
   top: 36px;
}

#nav-icon3.open span:nth-child(1) {
   top: 18px;
   width: 0%;
   left: 50%;
}

#nav-icon3.open span:nth-child(2) {
   -webkit-transform: rotate(45deg);
   -moz-transform: rotate(45deg);
   -o-transform: rotate(45deg);
   transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
   -webkit-transform: rotate(-45deg);
   -moz-transform: rotate(-45deg);
   -o-transform: rotate(-45deg);
   transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
   top: 18px;
   width: 0%;
   left: 50%;
}

/* Icon 4 */
