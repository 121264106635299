.FullImage {
   
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
  
    /* top:0px;
    left:0px;
    width:100%;
    height:100vh;
    box-sizing:border-box;
    overflow-x: hidden; */
   
 }

 .FullImage.show {
    opacity: 1;
    pointer-events: all;
 }


.FullImage .thumbnail{
    filter:blur(5px);
    -webkit-filter: blur(5px);
    position:fixed;
    /* top:50%;
    left:50%;
    transform: translate(-50%, -50%); */
   
 }
 
 .FullImage .image{
    opacity: 0;
    transition: opacity 2s;
    position:fixed;
    /* top:50%;
   
    transform: translate(0, -50%); */
 }
 
 .FullImage .image.show{
    opacity: 1;
    transition: opacity 1s;
    /* ; */
 }


 .LargeImage .note {
    font-size: 16px;
    position: absolute;
    text-align: center;
    /* color:#57ACC5; */
    color: #959595;
    bottom: 27px;
    /* right:calc(4vw + 2px); */
    left: 50%;
 
    transform: translate(-50%, 0%);
    /* margin-right:auto;
   margin-left:auto; */
    line-height: 14px;
    font-weight: bold;
 }
 .LargeImage .note span {
    font-size: 11px;
    font-weight: 400;
 }
 
 .FullImage .frame {
    box-sizing:border-box;
    position:fixed;
    
   
 }